<template>
    <div class="bg-img-custom p-4">
        <!-- <PWAPrompt :promptOnVisit="1" :timesToShow="3" :permanentlyHideOnDismiss="false" /> -->
        <div class="justify-content-center">
            <div class="row">
                <div class="col-lg-12 p-5 text-center">
                    <div class="mx-auto mb-5">
                        <a href="/">
                            <img src="@assets/images/logo.png" alt height="48" />
                        </a>
                        <h6 class="text-white">Version: {{ currentVersion }}</h6>                        
                        <template v-if="$store.getters.loggedInUser">
                            <h4 @click="clearMyAccount" class="text-white">Welcome ! {{ $store.getters.loggedInUser.name
                                }}</h4>
                        </template>
                        <h6 v-if="currentLocation" class="text-white">{{ currentLocation.name }}</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bar p-2" v-if="currentLocation">
            <h4 class="text-white">How Can I Help You?</h4>
            <b-button id="goFS" @click="needhelp" block variant="danger">I need help !</b-button>
            <b-button id="goFS" @click="viewProcedures" block variant="primary">View
                Procedures</b-button>
        </div>
        <prompt-user :openModal="openUserModal" @closeMe="closeNewUser"></prompt-user>
    </div>
</template>

<script>

import {
    externalData
} from '@/src/state/api/pwaexternal';

import {
    authenticateData
} from '@/src/state/api/authenticate';

import {
    version
} from "@/package.json";

import promptUser from './promptUser.vue'

export default {
    page: {
        title: 'Lets get started',
    },
    data() {
        return {
            deferredPrompt: null,
            currentVersion: version,
            currentLocation: null,
            openUserModal: false,
            mustHaveUser: false
        }
    },
    computed: {
        locationId() {
            return this.$route.params.locationId;
        },
    },
    components: {
        promptUser
    },
    async created() {
        await this.loadLocation()
    },
    methods: {
        async loadLocation() {
            this.currentLocation = await externalData.searchLocationByCode({
                qrCode: this.locationId,
                locationId: this.locationId
            })
            if (this.currentLocation) {
                this.mustHaveUser = this.currentLocation.mustHaveUser;
                this.$store.dispatch("setLoggedInLocation", this.currentLocation);

                if (this.mustHaveUser && (!this.$store.getters.loggedInUser)) {
                    this.openUserModal = true;
                }
            }
        },
        async viewProcedures() {
            
            if (!this.mustHaveUser) {
                this.$router.push(`/openapp/${this.currentLocation.keyId}/view-procedure`)
                return;
            }

            if (this.$store.getters.loggedInUser) {
                await this.createUser();
                this.$router.push(`/openapp/${this.currentLocation.keyId}/view-procedure`)
                return;
            }

            this.openUserModal = true;
        },
        async needhelp() {
            if (this.$store.getters.loggedInUser) {
                await this.createUser();
                this.$router.push(`/openapp/${this.currentLocation.keyId}/default-messaging`)
                return;
            }
            this.openUserModal = true;
        },
        async createUser() {            
            var result = await authenticateData.createUser({
                mobileNumber: this.$store.getters.loggedInUser.mobile,
                name: this.$store.getters.loggedInUser.name,
                requestDateTime: new Date(),
                ownerCompanyId: this.currentLocation.companyKeyId,
                locationId: this.currentLocation.keyId
            })
            if (result.succeeded) {
                this.$store.dispatch("setLoggedInUser", {
                    ...this.$store.getters.loggedInUser,
                    token: result.data.token
                });
            }
        },
        closeNewUser() {
            this.openUserModal = false
        },
        clearMyAccount() {
            if (confirm("are you sure ?")) {
                this.$store.dispatch("setLoggedInUser", null);
            }
        }
    }
}
</script>
